import '../global.css';

const TimelineSection = () => (
    <section className='timeline-section'>
       <h2>Timeline</h2>
       <div  className='timeline-items-container'>
        <div className='timeline-item'>
            <img src='/Icon-1.png' alt='wedding icon' />
            <h3>Ceremonia Religiosa</h3>
            <p>Acompáñanos a unir nuestras vidas con la bendición de Dios.</p>
        </div>
        <div className='timeline-item'>
            <img src='/Icon-2.png' alt='wedding icon' />
            <h3>Recepción</h3>
            <p>Tomate fotos con nosotros, mientras disfturamos juntos de un trago.</p>
        </div>
        <div className='timeline-item'>
            <img src='/Icon-3.png' alt='wedding icon' />
            <h3>Boda Civil</h3>
            <p>Acompáñanos al acontecimiento que oficializa todo.</p>
        </div>
        <div className='timeline-item'>
            <img src='/Icon-4.png' alt='wedding icon' />
            <h3>Primer Baile</h3>
            <p>Acompáñanos al momento más emotivo de la fiesta.</p>
        </div>
        <div className='timeline-item'>
            <img src='/Icon-5.png' alt='wedding icon' />
            <h3>Banquete</h3>
            <p>Disfrutaremos de una deliciosa cena.</p>
        </div>
        <div className='timeline-item'>
            <img src='/Icon-6.png' alt='wedding icon' />
            <h3>Baile</h3>
            <p>Impresiónanos con tus mejores pasos en la pista.</p>
        </div>
       </div>
    </section>
);

export default TimelineSection;