import '../global.css';

const DessCodeSection = () => (
    <section className='dresscode-section'>
        <h2>Código de vestimenta</h2>
        <div className='dresscode-items-container'>
            <div className='dresscode-item'>
                <img src='Icon-max-2.png' alt='Suit icon' />
                <span>Traje y Corbata</span>
            </div>
            <div className='dresscode-item'>
                <img src='Icon-max-3.png' alt='Dress icon' />
                <span>Vestido Largo</span>
            </div>
        </div>
        <div className='dresscode-text-container'>
            <div className='dresscode-decorator'></div>
            <span>Formal</span>
            <div className='dresscode-decorator'></div>
        </div>
    </section>
);

export default DessCodeSection;