import '../global.css';

const HeroSection = () => (
    <section className='hero-section'>
        <img src='/logo.png' alt='Pilar & Abraham logo' />
        <div className='hero-date-info'>
            <div className='hero-decoration-bar'></div>
            <span>SAVE THE DATE</span>
            <div className='hero-decoration-bar'></div>
        </div>
        <p>Sábado, 2 de Diciembre del 2023</p>
    </section>
);

export default HeroSection;