import React from 'react';
import '../global.css';

const CounterSection = () => {
    return (
        <section className='counter-section'>
            <div className='counter-main-info'>
                    <>
                        <h3>¡Hoy es el gran día!</h3>
                    </>
            </div>
        </section>
    );
    
}

export default CounterSection;