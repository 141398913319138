import '../global.css';

const GiftTable = () => (
    <section className='gifttable-section'>
        <h2>Mesa de regalos</h2>
        <div className='gifttable-card'>
            <img src='/liverpool.png' alt='Liverpool Logo' />
            <span>Evento: 51301338</span>
            <div className='information-btn'>
                <a href='https://mesaderegalos.liverpool.com.mx/milistaderegalos/51301338' target='_blank'>Ir a la Mesa</a>
            </div>
        </div>
    </section>
);

export default GiftTable; 