import '../global.css';

const PoemSection = () => (
    <section className='poem-section-main'>
        <h2>¡Nos Casamos!</h2>
        <div className='poem-main-container'>
            <p>“Tus manos son mi caricia,</p>
            <p>mis acordes cotidianos;</p>
            <p>te quiero porque tus manos</p>
            <p>trabajan por la justicia.</p>
            <br />
            <p>No te quiero por tu cara,</p>
            <p>ni por tus años, ni por tus palabras,</p>
            <p>ni por tus intenciones.</p>
            <p>Te quiero porque estás hecho</p>
            <p>de buena madera".</p>
            <span>-Mario Benedetti-</span>
        </div>
        <p className='poem-text'>¡Nos hará muy felices que nos acompañes en este día tan especial!</p>
    </section>
);

export default PoemSection;