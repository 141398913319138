import '../global.css';

const HotelsSection = () => (
    <section className='hotels-section'>
       <h2>Hospedaje</h2>
       <div className='hotels-items-container'>
        <img src='/Icon-max-1.png' alt='hotels icon' />
        <div className='hotels-item'>
            <h3>Hotel Excelencia de América</h3>
            <p>El hotel más cercano a la fiesta, ideal para toda la familia.</p>
            <p>Reservaciones al (445) 458 0537</p>
            <div className='information-btn'>
                <a href='https://maps.app.goo.gl/KveBBCcQ5FgXvPPs8' target='_blank'>Ir al Mapa</a>
            </div>
        </div>
        <div className='hotels-item'>
            <h3>Hotel La Parroquia</h3>
            <p>Para que disfrutes del centro de la ciudad.</p>
            <p>Reservaciones al (445) 457 0622</p>
            <div className='information-btn'>
                <a href='https://maps.app.goo.gl/G2gW18GLGtnYCogf7' target='_blank'>Ir al Mapa</a>
            </div>
        </div>
        <div className='hotels-item'>
            <h3>Hotel Boutique La Cantera</h3>
            <p>Para que te relajes un rato.</p>
            <p>Reservaciones al (445) 688 2730</p>
            <div className='information-btn'>
                <a href='https://maps.app.goo.gl/9J8xoHysLFtN1p9K9' target='_blank'>Ir al Mapa</a>
            </div>
        </div>
       </div>
    </section>
);

export default HotelsSection;