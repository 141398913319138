import '../global.css';

const ConsiderationsSection = () => (
    <section className='considerations-section'>
        <h2>Mesa de regalos</h2>
        <p>Queremos que sea un día perfecto, como lo hemos soñado.</p>
        <div className='considerations-text'>
            <div className='considetations-decorator'></div>
            <span>SIN NIÑOS</span>
            <div className='considetations-decorator'></div>
        </div>
        <p>Para permitir que todos los invitados la pasemos genial, hemos decidido que el día de nuestro
matrimonio sea una ocasión sólo para adultos.</p>
    </section>
);

export default ConsiderationsSection; 