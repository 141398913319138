import '../global.css';

const InformationSection = () => (
    <section className='information-section'>
        <div className='information-items-container'>
            <div className='information-item'>
                <img src='/Icon-1.png' alt='Church pink icon' />
                <h2>Ceremonia</h2>
                <hr />
                <p>Sábado, 2 de Diciembre del 2023</p>
                <p>4:00 pm.</p>
                <span>"CASICIACO"</span>
                <p className='information-adress'>Col. Cruz Grande, Yuriria, Guanajuato C.P 38944.</p>
                <div className='information-btn'>
                    <a href='https://maps.app.goo.gl/rnuoNJ48o3CFiThBA' target='_blank'>Ir al Mapa</a>
                </div>
            </div>
            <div className='information-item'>
                <img src='/Icon-2.png' alt='Church pink icon' />
                <h2>Recepción</h2>
                <hr />
                <p>Sábado, 2 de Diciembre del 2023</p>
                <p>6:00 pm.</p>
                <span>"Salón Quinta Real La Jolla"</span>
                <p className='information-adress'>Blvd. Uriangato 326, La Joyita, 38985 Uriangato, Gto.</p>
                <div className='information-btn'>
                    <a href='https://maps.app.goo.gl/f27tjW1Tra87q2HE7' target='_blank'>Ir al Mapa</a>
                </div>
            </div>
        </div>
    </section>
);

export default InformationSection;