import '../global.css';

const CollageSection = () => (
    <section className='collage-section'>
       <div className='collage-photo photo-1'></div>
       <div className='collage-photo photo-2'></div>
       <div className='collage-photo photo-3'></div>
       <div className='collage-photo photo-4'></div>
       <div className='collage-photo photo-5'></div>
       <div className='collage-photo photo-6'></div>
    </section>
);

export default CollageSection; 